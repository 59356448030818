export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Dashboard",
        to: "/dashboard",
        icon: "cil-speedometer",
        badge: {
          color: "primary",
          text: "NEW",
        },
      },
      {
        _name: "CSidebarNavDropdown",
        name: "role.sidebar.data.title",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "role.sidebar.data.admin",
            to: "/data/role",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.data.profile",
            to: "/data/profile",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.data.customers",
            to: "/data/customers",
            icon: "cil-puzzle",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "role.sidebar.products.title",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "role.sidebar.products.products",
            to: "/products/products",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.products.showcase",
            to: "/products/showcase",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.products.settings",
            to: "/products/settings",
            icon: "cil-puzzle",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "role.sidebar.logistics.title",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "role.sidebar.logistics.connections",
            to: "/logistics/connections",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.logistics.planning",
            to: "/logistics/planning",
            icon: "cil-puzzle",
          },
          // {
          //   name: "role.sidebar.logistics.delivery",
          //   to: "/logistics/deliveries",
          //   icon: "cil-puzzle",
          // },
          // {
          //   name: "role.sidebar.logistics.settings",
          //   to: "/logistics/operations",
          //   icon: "cil-puzzle",
          // },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "role.sidebar.orders.title",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "role.sidebar.orders.current",
            to: "/orders/current",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.orders.incoming",
            to: "/orders/next",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.orders.archive",
            to: "/orders/archive",
            icon: "cil-puzzle",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "role.sidebar.finance.title",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "role.sidebar.finance.wallet",
            to: "/finance/wallet",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.finance.invoice",
            to: "/finance/invoices",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.finance.archive",
            to: "/finance/archive",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.finance.settings",
            to: "/finance/settings",
            icon: "cil-puzzle",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "role.sidebar.export.title",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "role.sidebar.export.taxes",
            to: "/export/tax",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.export.ivoices",
            to: "/export/invoices",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.export.products",
            to: "/export/products",
            icon: "cil-puzzle",
          },
          {
            name: "role.sidebar.export.orders",
            to: "/export/orders",
            icon: "cil-puzzle",
          },
        ],
      },
    ],
  },
];
