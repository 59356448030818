<template>
  <CSidebar
    colorScheme="dark"
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand to="/">
      <img
        height="40"
        alt="Loonity Logo"
        class="c-sidebar-brand-full"
        src="./../../public/loonity/brand/LoonityLogoWhite.svg"
      />
      <img
        height="40"
        alt="Loonity Logo"
        class="c-sidebar-brand-minimized"
        src="./../../public/loonity/brand/LoonityLogoWhiteSyg.png"
      />
    </CSidebarBrand>
    <img class="responsive" :src="role.image_rect_cropped" :alt="role.name" />
    <TCRenderFunctionRole flat :contentToRender="$options.nav_role" />
    <!-- <div class="loonityWorkLink">
      <CButton
        size="lg"
        color="primary"
        block
        @click="$store.commit('toggle', 'asideShow')"
        >Cambia ruolo</CButton
      >
    </div>
    <div class="loonityBuyLink">
      <CButton size="lg" color="primary" block to="/"
        >Acquista su Loonity</CButton
      >
    </div> -->

    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import nav_role from "./_nav_role";
import TCRenderFunctionRole from "../common/TCRenderFunctionRole";
export default {
  name: "TheSidebar",
  nav_role,
  components: {
    TCRenderFunctionRole,
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },

    role() {
      return this.$store.state.role || {};
    },
  },
};
</script>
